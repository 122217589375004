.MainContentsDownload {
    position: absolute;
    width: 100%;
    align-items: center;
    margin-top: 70vw;
    text-align: center;
    padding-top: 160px;
}
.main-content-download { display: flex; width: 76vw; margin-left: 11vw; align-items: center }
.image-download { height: 540px }
.desc-download { padding-left: 50px; text-align: left }

@media screen and (max-width:1024px) {
    .MainContentsDownload {
        position: relative;
        width: 100%;
        align-items: center;
        margin-top: 0vw;
        text-align: center;
        padding-top: 12vw;
        padding-bottom: 10vw;
        border-top: solid 1px rgba(0,0,0,0.05);
    }
    .main-content-download { display: block; width: 100%; margin-left: 0vw; align-items: center }
    .image-download { height: 60vw }
    .desc-download { padding: 50px 50px 0px 50px; text-align: center }
}