* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
html, body {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.MainContents {
  min-height: 3000px;
  position: relative;
  margin-top: 70vw;
  background-image: url('./assets/images/content_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.main-subtitle {
  font-size: 20px;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(80,80,80,0.7);
}
.align-left {
  text-align: left;
}
.white { color: white; }

@media screen and (max-width:1024px) {
  .MainContents {
    background-image: none;
    min-height: auto;
    overflow: hidden;
    /* align-items: center; */
  }
  .white {
    color: #505050;
  }
  .align-left {
    text-align: center;
  }
}