.MainContentsPrivacy { width: 100%; align-items: center; text-align: center; padding-bottom: 15px !important; }
.second-toolbar {
    background-color: transparent;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
    box-shadow: 0px 5px 15px 0px rgb(80 80 80 / 5%)
}
.second-toolbar-button { margin-right: 20px }
.second-toolbar-title { width: 66%; text-align: right; font-weight: 900 }
.second-toolbar-active { background-color: #0198D7; box-shadow: 0px 5px 15px 0px rgb(80 80 80 / 5%); transition: all 0.5s ease }
.icon-back { color: #e74c3c }
.icon-back-scrolled { color: #fff }
.second-toolbar-title-scrolled { color: #fff }
.second-content { margin-top: 80px; width: 78vw; margin-left: 11vw; padding-top: 50px }
.second-content-desc { padding-top: 40px; padding-bottom: 50px; padding-left: 10px; padding-right: 20px; }
.h2-subtitle { margin-top: 40px; margin-bottom: 20px; text-align: left }
.h3-subtitle { color: rgba(80,80,80,0.9); text-align: left; margin-bottom: 10px; }
.p-desc { color: rgba(80,80,80,0.9); line-height: 30px; font-size: 16px; text-align: left; }
.p-desc-list {
    padding-left: 20px;
}

@media screen and (max-width:1024px) {
    .show-logo { display: none }
    .second-content-desc { padding-left: 5px; padding-right: 5px }
}