.MainContentsService {
    position: absolute;
    width: 100%;
    align-items: center;
    margin-top: 25vw;
    text-align: center;
    padding-top: 160px;
}
.main-content-service { width: 78vw; display: flex; margin-left: 11vw }
.main-content-service-grid { flex: 1; padding: 70px }
.main-content-service-grid-subtitle { font-size: 16px; text-align: center; margin-top: 20px; line-height: 26px }
.image-service { height: 150px; margin-bottom: 50px }

@media screen and (max-width:1024px) {
    .MainContentsService {
        position: relative;
        width: 100%;
        align-items: center;
        padding-left: 0px;
        margin-top: 0vw;
        padding-top: 10vw;
        padding-bottom: 10vw;
        border-top: solid 1px rgba(0,0,0,0.05);
    }
    .main-content-service { width: 100%; display: block; margin-left: 0vw }
    .main-content-service-grid { flex: 1; padding: 50px 50px 0px 50px }
    .image-service { height: 25vw; margin-bottom: 20px }
    .main-content-service-grid-subtitle { font-size: 16px; text-align: center; margin-top: 10px; line-height: 26px }
}