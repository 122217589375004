.MainContentsFooter {
    position: absolute;
    width: 100%;
    align-items: center;
    margin-top: 125vw;
    text-align: center;
    padding-top: 150px;
}
.main-content-footer {
    display: flex;
    width: 76vw;
    margin-left: 11vw;
    align-items: center;
}
.image-footer-knop {
    height: 30px;
    margin-bottom: 150px;
}
.main-content-footer-partner {
    padding-top: 50px;
}
.image-footer-partner {
    height: 50px;
}
.desc-footer {
    padding-left: 50px;
    text-align: left;
}
.main-content-footer-end {
    padding-top: 100px;
}
.image-footer-end {
    height: 152px;
}
.desc-footer-end {
    margin-top: 20px;
}

@media screen and (max-width:1024px) {
    .MainContentsFooter {
        position: relative;
        width: 100%;
        align-items: center;
        padding-left: 0px;
        margin-top: 0vw;
        padding-top: 10vw;
        padding-bottom: 15px;
        border-top: solid 1px rgba(0,0,0,0.05);
        padding-left: 30px;
        padding-right: 30px;
    }
    .image-footer-knop { display: none; }
}