.HeaderContainers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
}
.header-top-image-content { display: none }
.header-left { width: 50%; height: 50vw; display: contents }
.header-right {
    width: 50%;
    height: 50vw;
    justify-content: end;
    position: relative;
}
.header-left-container {
    width: 50%;
    padding-left: 11.3vw;
    padding-right: 20px;
    text-align: left;
}
.header-left-title { text-align: left }
.header-left-subtitle{
    font-size: 20px;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: rgba(80,80,80,0.7);
    text-align: left;
}
.link-download-play-store { text-decoration: none; cursor: pointer }
.img-download-play-store { height: 60px }
.header-right-img-container {
    height: 50vw;
    width: auto;
    position: absolute;
    right: 0px;
}
.header-right-img-item {
    position: absolute;
    left: 75px;
    bottom: 50px;
    height: 75%;
}

@media screen and (max-width:1024px) {
    .HeaderContainers {
        display: contents;
        align-items: center;
        text-align: center;
        width: 100%;
        overflow: hidden;
    }
    .header-top-image-content {
        z-index: 1;
        display: block;
        width: 100%;
        padding-top: 30vw;
    }
    .header-top-image { height: 90vw }
    .header-left {
        position: relative;
        top: 20vw;
        width: 100%;
        display: block;
        z-index: 1;
    }
    .header-right { 
        /* position: absolute; right: -140%; top: 0  */
        position: absolute; 
        right: -140%; 
        top: 0;
    }
    .header-left-container {
        width: 100%;
        padding-left: 10vw;
        padding-right: 10vw;
        text-align: center;
    }
    .header-left-title { text-align: center }
    .header-left-subtitle{ text-align: center }
    .header-right-img-container { 
        /* display: none; */
        overflow: hidden;
        height: 746px; z-index: -1 
    }
    .header-right-img-item { display: none }
}