.MainContentsWhy {
    position: relative;
    width: 78vw;
    align-items: center;
    padding-left: 11vw;
}
.main-content-why-top {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 78vw;
    left: 11vw;
    top: -27vw;
}
.image-why { width: 25% }
.image-why-content { width: 20%; margin-bottom: 10px }
.desc-why { padding-top: 100px; padding-left: 40px; width: 40% }
.main-content-why-bottom {
    background-image: linear-gradient(to right, #0098D8 , #244A64);
    border-radius: 25px;
    width: 78vw;
    min-height: 300px;
    left: 11vw;
    position: absolute;
    top: -100px;
    display: flex;
    overflow: hidden;
}
.main-content-why-bottom-grid {
    text-align: left;
    cursor: pointer;
    flex: 1 1;
    padding: 30px;
}
.main-content-why-bottom-grid:hover { background-color:rgba(43, 87, 117, 0.7) }
.main-content-why-bottom-grid-subtitle {
    font-size: 16px;
    text-align: left;
    margin-top: 15px;
    line-height: 26px;
}
.main-content-why-bottom-responsive { display: none }

@media screen and (max-width:1024px) {
    .MainContentsWhy {
        position: relative;
        width: 100%;
        align-items: center;
        padding-left: 0px;
        padding-bottom: 7vw;
    }
    .main-content-why-top {
        position: relative;
        width: 100%;
        text-align: center;
        left: 0vw;
        top: 0vw;
        padding-left: 50px;
        padding-right: 50px;
    }
    .align-left { text-align: center }
    .image-why { display: none }
    .desc-why { padding-top: 100px; padding-left: 0px; width: 100% }
    .main-content-why-bottom { display: none }
    .main-content-why-bottom-responsive { display: block; }
    .main-content-why-bottom-grid { text-align: center; min-height: 100px }
    .main-content-why-bottom-grid:hover { background-color:#fff }
}