.NavbarItems {
    background-color: transparent;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.nav-background { background-color: #0198D7; box-shadow: 0px 5px 15px 0px rgb(80 80 80 / 5%); transition: all 1s ease; }
.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    height: 40px;
    width: auto;
    margin-top: -5px;
}
.fa-bars { color: #fff; font-size: 24px; }
.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    padding-right: 10px;
    margin-top: -5px;
}
.nav-links { color:rgba(255, 255, 255, 0.6); text-decoration: none; padding: 1rem 0.3rem; font-size: 14px; }
.nav-links:hover { color: #fff; font-weight: bold; }
.nav-links-mobile { display: none; }
.menu-icon { display: none; }
.menu-active { color: #fff; font-weight: 900; }

@media screen and (max-width:1024px) {
    .NavbarItems { 
        position: relative; 
        position: fixed; 
        width: 100%;
        /* display: block; */
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 70px;
        left: 100%;
        opacity: 1;
        transition: all 0.5s ease;
        
        /* display: none; */
    }
    .nav-menu.active {
        background-color: #0198D7;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding-right: 0px;
    }
    .nav-links { text-align: center; padding: 1.5em; width: 100%; display: table; }
    .nav-links:hover { background-color: #0198D7; border-radius: 0; color: #fff;
    }
    .navbar-logo { position: absolute; top: -2px; left: 0; transform: translate(25%, 50%); }
    .menu-icon {
        display: block;
        position: absolute;
        top: -12px;
        right: 20px;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    /* .fa-bars { color: #FFF } */
    .fa-times { color:#fdcb6e; font-size: 24px; margin-right: 3px; }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        border-radius: 4px;
        width: 100vw;
        background-color:#1174A2;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        font-weight: 900;
        position: absolute;
        bottom: 70px;
        left: 0;
    }
    .nav-links-mobile:hover { background-color: #2B5775; color: #fff; transition: 250ms; }
    .menu-active { color: #fff !important; font-weight: 900; }
    .nav-links { color:rgba(255, 255, 255, 0.6); }
    Button { display: none; }
}